<template>
  <div id="index">
    <dv-full-screen-container class="container">
      <div class="row-container">
        <div class="threed">
          <div class="main-header">

          </div>
        </div>
        <div style="text-align: left;
    margin-top: 1%;">
          <el-button

              class="filter-item"
              type="primary"
              icon="el-icon-download"
              style="text-align: center;background:rgb(0, 35, 95);"
              @click="jump()"
          >
            返回上级
          </el-button>
        </div>
      </div>
      <transition name="slide-fade">
        <div class="left_info">
          <dv-border-box-10 style="height:25%; box-shadow: rgb(121 187 255 / 79%) 0px 0px 25px;">
            <dv-decoration-11 style="
            position: absolute;width: 200px;height: 40px;font-size: smaller;font-weight: bolder;top: -10%;">
              案件上报分析
            </dv-decoration-11>

            <maintenance_pie></maintenance_pie>

          </dv-border-box-10>

          <dv-border-box-10 style="height:62.5%; box-shadow: rgb(121 187 255 / 79%) 0px 0px 25px;margin-top: 5%">
            <dv-decoration-11 style="
            position: absolute;width: 200px;height: 40px;font-size: smaller;font-weight: bolder;top: -5%;">
              部门处置情况
            </dv-decoration-11>
            <div id="tab" >
              <div class="tab-tit">
                <!--点击设置curId的值  如果curId等于0，第一个a添加cur类名，如果curId等于1，第二个a添加cur类名，以此类推。添加了cur类名，a就会改变样式 @click,:class ,v-show这三个是vue常用的指令或添加事件的方式-->
                <a href="javascript:;" @click="curId=0" :class="{'cur':curId===0}">市容管理</a>
                <a href="javascript:;" @click="curId=1" :class="{'cur':curId===1}">道路保洁</a>
                <a href="javascript:;" @click="curId=2" :class="{'cur':curId===2}">河道养护</a>
                <a href="javascript:;" @click="curId=3" :class="{'cur':curId===3}">绿化养护</a>
                <a href="javascript:;" @click="curId=4" :class="{'cur':curId===4}">道路养护</a>
                <a href="javascript:;" @click="curId=5" :class="{'cur':curId===5}">管道养护</a>
              </div>
              <div style="width: 100%;margin-top:10%;height: 100%">
               <stacked_horizontal_bar :parent_type="curId"></stacked_horizontal_bar>
              </div>
            </div>
          </dv-border-box-10>




        </div>
      </transition>



      <transition name="slide-fade">
        <div class="center_info">


          <dv-border-box-10 style="height:25%; box-shadow: rgb(121 187 255 / 79%) 0px 0px 25px;">
            <dv-decoration-11 style="
            position: absolute;width: 200px;height: 40px;font-size: smaller;font-weight: bolder;top: -3%;">
              案件信息
            </dv-decoration-11>

                  <div  class='systemInfo'
                        style="height: 95%;
    width: 95%;
    overflow-y: auto;
    position: absolute;
    top:6%;
    left: 4%;
    ">
                    <table style="font-size: x-small;border-color: unset;width: 100%;line-height: 30px;" class="my_table_1" >
                      <thead >
                      <tr >
                        <td>大类</td>
                        <td>子类</td>
                        <td>上报人</td>
                        <td>上报日期</td>

                        <td>处理状态</td>
                      </tr>
                      </thead>
                      <tbody >
                      <tr v-for="(item,key) in arr2" :key="key" @click="checkInfo(key)">
                        <td>{{item.type}}</td>
                        <td>{{item.child_type}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.date}}</td>
                        <td v-if="item.status==2" style="color: rgb(16 231 118);">已处理</td>
                        <td style="color: yellow" v-if="item.status==1">待处理</td>
                      </tr>

                      </tbody>
                    </table>
                  </div>

          </dv-border-box-10>
          <dv-border-box-11 title="地图看板" style="height: 67%">
            <worker-map></worker-map>

          </dv-border-box-11>

        </div>
      </transition>
      <transition name="slide-fade">
        <div class="right_info">
          <dv-border-box-10 style="height:25%; box-shadow: rgb(121 187 255 / 79%) 0px 0px 25px;">
            <dv-decoration-11 style="
            position: absolute;width: 200px;height: 40px;font-size: smaller;font-weight: bolder;top: -10%;">
              案件处置分析
            </dv-decoration-11>
            <div class="first_li" style="width:100%">
              <div >

                <div class="report_count">
                  <ul style="list-style:none; width: 13%;">
                    <li>上报数</li>
                    <li style="font-size: x-large;" class="article">
                      {{report}}
                    </li>
                  </ul>
                  <ul style="list-style:none;">
                    <li>结案量</li>
                    <li class="article" style="font-size: x-large;">{{case_end}}</li>

                  </ul>
                  <ul style="list-style:none;">
                    <li>作废量</li>
                    <li class="article" style="font-size: x-large;">{{case_back}}</li>
                  </ul>
                </div>
              </div>
              <div class="second_li" style="width: unset;left: unset">
                <div class="unit_type" style="width: unset;margin-left: 12%">
                  <span >市容管理:</span>
                  <span class="article" >{{listing.city_appearance}}</span>
                  <span style="margin-left: 25px">绿化养护：</span>
                  <span class="article" >{{listing.green_conservation}}</span>
                  <span style="margin-left: 25px">道路养护：</span>
                  <span class="article" >{{listing.road_maintenance}}</span>
                  <span style="margin-left: 3px">河道养护：</span>
                  <span class="article" >{{listing.river_maintenance}}</span>
                  <span style="margin-left: 25px">管道养护：</span>
                  <span class="article" >{{listing.piping_maintenance}}</span>
                  <span style="margin-left: 25px">道路保洁：</span>
                  <span class="article" >{{listing.road_cleaning}}</span>
                </div>
              </div>
            </div>
          </dv-border-box-10>







          <dv-border-box-10 style="height:62.5%; box-shadow: rgb(121 187 255 / 79%) 0px 0px 25px;margin-top: 5%">
            <dv-decoration-11 style="
            position: absolute;width: 200px;height: 40px;font-size: smaller;font-weight: bolder;top: -3%;">
              案件详情
            </dv-decoration-11>

            <div style="width: 90%;position: absolute;left: 5%;top:10%;height: 50%">
              <dv-border-box-8 >
                <el-image v-if="is_img_show==true"
                    style="width: 95%; height: 95%;margin-top: 2%"
                    :src="info2.img_url[index]"
                    :preview-src-list="[info2.img_url[index]]"
                />

                <div style="position: absolute;
    top: 42%;
    width: 100%;display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;" v-if="is_next==true&&is_img_show==true">
                  <a href="javascript:void(0)" @click="prev" v-show="index!=0" class="right" >
                    <img src="~@/assets/icon/left.png" alt="" style="width: 50px;height: 50px"/>
                  </a>
                  <a href="javascript:void(0)" @click="next" v-show="index<info2.img_url[index].length-1" class="left" >
                    <img src="~@/assets/icon/right.png" alt="" style="width: 50px;height: 50px"/>
                  </a>


                </div>

                <!-- 右箭头 -->
              </dv-border-box-8>

            </div>
            <div style="width: 90%;position: absolute;left: 5%;top:60%;height: 50%;text-align: left">
              <div class="clean_info" style="line-height: 30px;white-space:normal">
              <div class="order_title">上报人员：<sapn>{{info2.name}}</sapn></div>
              <div class="order_title">上报时间：<sapn>{{info2.time}}</sapn></div>
              <div class="order_title">所属片区：<sapn>{{info2.street}}</sapn></div>
              <div class="order_title">上报事项：<sapn style="color: yellow">{{info2.remark}}</sapn></div>
              <div class="order_title">上报地点：<sapn>{{info2.address}}</sapn></div>
              </div>
            </div>

          </dv-border-box-10>

        </div>
      </transition>
    </dv-full-screen-container>
  </div>
</template>

<script>
import stacked_horizontal_bar from "@/components/New/Echarts/stacked_horizontal_bar";
import maintenance_pie from "@/components/New/Echarts/maintenance_pie";
import WorkerMap from "@/components/Center1/Map/WorkerMap";
//import stage_speed_gauge from "@/components/New/Echarts/stage_speed_gauge";
// import basic_line_chart from "@/components/New/Echarts/basic_line_chart";
// import basic_line_chart_1 from "@/components/New/Echarts/basic_line_chart_1";
// import basic_line_chart_2 from "@/components/New/Echarts/basic_line_chart_2";
import {
  summaryReporting,
  eventReportingList
}from '@/api/maintenance'


export default {
name: "InfoView",
  components:{
    WorkerMap,
    stacked_horizontal_bar,
    maintenance_pie
    //stage_speed_gauge,
    // basic_line_chart,
    // basic_line_chart_1,
    // basic_line_chart_2,


  },
  data(){
    return{
      curId: 0,
      disabled:true,
      info2:{

      },
      index:0,
      report:0,
      case_end:0,
      case_back:0,
      timer: "",
      arr2:[],
      is_img_show:false,
      append_to_arr2:[
        {
          type:'绿化养护',
          child_type:'绿化带垃圾',
          name:'李高春',
          date:'2023-3-21',
          status:2,
          info2: {
            name:'李高春',
            street:'申北片区',
            address:'春光路742号',
            time:'2023-3-21',
            remark:'绿化带垃圾',
            img_url:[
              require('@/assets/live_photo/green.jpg'),
              //require('@/assets/live_photo/green(yes).jpg')
            ],
          }
        },
        {
          type:'市容管理',
          child_type:'乱堆物',
          name:'汪建国',
          date:'2023-3-21',
          status:2,
          info2: {
            name:'汪建国',
            street:'申北片区',
            address:'申北路263号',
            time:'2023-3-21',
            remark:'乱堆物',
            img_url:[
              require('@/assets/live_photo/263.jpg'),
             // require('@/assets/live_photo/263(yes).jpg')
            ],
          }
        },
        {
          type:'绿化养护',
          child_type:'绿化带垃圾',
          name:'葛允梅',
          date:'2023-3-21',
          status:2,
          info2: {
            name:'葛允梅',
            street:'申北片区',
            address:'申北路316',
            time:'2023-3-21',
            remark:'毁绿种菜',
            img_url:[
              require('@/assets/live_photo/green2.jpg'),
             // require('@/assets/live_photo/green(2)(yes).jpg')
            ],
          }
        },
        {
          type:'市容管理',
          child_type:'小包垃圾',
          name:'于贵兴',
          date:'2023-3-21',
          status:2,
          info2: {
            name:'于贵兴',
            street:'申北片区',
            address:'申长北路185弄',
            time:'2023-3-21',
            remark:'小包垃圾',
            img_url:[
              require('@/assets/live_photo/rg.jpg')
              //require('@/assets/live_photo/ad(yes).jpg')
            ],
          }
        },
        {
          type:'市容管理',
          child_type:'跨门营业',
          name:'喻光明',
          date:'2023-3-21',
          status:2,
          info2: {
            name:'喻光明',
            street:'申北片区',
            address:'中春路',
            time:'2023-06-11',
            remark:'乱设摊',
            img_url:[
              require('@/assets/live_photo/luan.jpg'),
              //require('@/assets/live_photo/black_ad(yes).jpg')
            ],
          }
        },
        {
          type:'管道养护',
          child_type:'管道堵塞',
          name:'严献军',
          date:'2023-6-11',
          status:2,
          info2: {
            name:'严献军',
            street:'申北片区',
            address:'沪闵路',
            time:'2023-3-21',
            remark:'污水溢出',
            img_url:[
              require('@/assets/live_photo/guandao.jpg'),
            //  require('@/assets/live_photo/guandao(yes).jpg')
            ],
          }
        },

      ],
      number:0,
      is_true:[],
      flag:false,
      is_next:true,
      listing:[],
      random_number:[
        {
          name:'city_appearance',

        },
        {
          name:'road_cleaning',

        },
        {
          name: 'river_maintenance',

        },
        {
          name:'green_conservation',

        },
        {
          name:'road_maintenance',

        },
        {
          name:'piping_maintenance',

        }
      ],
      list:[]
    }
  },
  mounted() {

    this.getList()
    this.eventsReporting()
    setTimeout(()=>{
      this.checkInfo(0)
    },500)

    this.start()
  },

  methods:{
    eventsReporting(){
      var that=this
      eventReportingList().then(response=>{
        that.arr2=response.data
        console.log('我的测试数据',that.arr2)
      })
    },
    getList(){
      var that=this
      summaryReporting().then(response=>{
        that.listing=response.data.maintaintype
        that.report=response.data.total_reporting
        that.case_end=response.data.close_case
        that.case_back=response.data.is_back
        //console.log('测试',that.listing.city_appearance)
      })
    },
    start(){
      this.timer = setInterval(this.valChange, 30000)
    },
    valChange(){
      // this.report++
      // this.number=Math.round(Math.random()*5)
      //
      //
      // this.listing[this.random_number[this.number].name]++
      //
      //
      // this.arr2.unshift(this.append_to_arr2[this.number])



    },
    prev: function () {//prev 切换到上一张图片

      this.index--;//此处this指当前对象#mask

    },

    next: function () {//next 切换到下一张图片

      this.index++;//此处this指当前对象#mask

    },
    checkInfo(index){
      let that=this
      //console.log('初始化赋值',that.arr2)
      if(that.arr2!=''){
        that.is_next=true
        that.is_img_show=true
        if(that.arr2[index].info2!=='undefined') {
          that.info2=that.arr2[index].info2
        }
        console.log('初始化赋值',that.info2)
        if(that.info2.img_url.length==1){
          that.is_next=false
        }
      }


    },

    jump(){
      this.disabled=false
      this.$router.back(-1);
    },

  },
  beforeUnmount() {
    this.timer = null // 这里最好
    clearInterval(this.timer);

  },
}
</script>

<style lang="less" scoped>
#index{
  color: #f9f9f9;
  background-color: #000000;
  width: 100%;
  height: 100%;
  .container {
    padding: 0.4rem 0.4rem 0.3rem 0.7rem;

  }
  tbody tr:hover{cursor:pointer;background-color: rgba(35,198,200,0.3);
    cursor: pointer;
  }
  .el-tabs__item {
    padding: 0 20px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 15px;
    font-weight: 500;
    color: white;
    position: relative;
  }
  #tab{
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 5%;
    height: 95%;
  }
  .tab-tit{
    font-size: 0;
    width: 100%;
  }
  .tab-tit a{
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
    font-weight: bold;
    width: 25%;
    text-align: center;
    background: #ecf5ff24;
    color: #333;
    color: wheat;
    text-decoration: none;

    border: 1px solid var(--el-color-white);
    //box-shadow: 0 0 0 8px #dce3e3;
    border-radius: 10px;
  }

  .tab-tit .cur{
    background: #09f;
    color: #fff;
  }
  .tab-con div{



  }
  .right_ul{
    position: absolute;
    top: 5%;
    /* left: 2%; */
    width: 92%;
    height: 92%;
    /* text-align: left; */
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    list-style: none;
    line-height: 500%;
    font-weight: bold;

  }
  .third_li_3{
    position: absolute;
    top: 8%;
    width: 20%;
    height: 92%;

    left: 80%;
    font-size: 15px;
    list-style: none;
    line-height: 250%;
    font-weight: bold;
  }
  .third_li_2{
    position: absolute;
    top: 8%;
    width: 60%;
    height: 92%;

    left: 20%;

  }
  .third_li{
    position: absolute;
    top: 8%;
    width: 20%;
    height: 92%;
    font-size: 15px;
    list-style: none;
    line-height: 500%;
    font-weight: bold;

  }
  .unit_type{
    flex-wrap: wrap;
    display: flex;
    margin: 4%;
    font-size: 13px;
    font-weight: bold;
    line-height: 260%;
    width: 76%;

  }
  .article{
    background: #eda50f linear-gradient(135deg, transparent 25%, transparent 40%, var(--el-color-warning) 40%, #e0de13 60%, transparent 60%, transparent) no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: scratchy 1.5s linear infinite;
    animation: scratchy 1.5s linear infinite;
  }
  .report_count_2{
    position: absolute;
    top: -11%;
    left: 40%;
    /* width: 40%; */
    font-size: 15px;
    list-style: none;
    line-height: 200%;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .report_count{
    position: absolute;
    top: 0%;
    width: 100%;
    font-size: 15px;
    list-style: none;
    line-height: 300%;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
  }
  .classic ul{
    font-size: 13px;
    float: left;
    list-style:none;
    width: 23%;
    line-height: 230%;
    font-weight: bold;
    border-right: 2px dashed var(--el-color-white);
    text-align: center;
  }
  .classic{

    width: 95%;
    position: absolute;
    top: 18%;
    height: 78%;
    left: 2%;
  }
  .second_li{

    width: 95%;
    height: 26%;
    position: absolute;
    top: 83%;
    height: 26%;
    left: 12%;
  }
  .first_li{

    width: 95%;
    position: absolute;
    top: 10%;
    height: 56%;
   // left: 2%;
  }
  .right_info{
   //ackground: #2b7de2;
    /* top: 29%; */
    position: absolute;
    width: 30%;
    top: 8%;
    left: 68.4%;
    height: 100%;
  }
  .left_info{

     //background: blueviolet;
    /* top: 29%; */
    position: absolute;
    width:30%;
    top: 8%;
    height: 100%;


  }
  .center_info{
    //background: #2b7de2;
    /* top: 29%; */
    position: absolute;
    width: 37%;
    top: 8%;
    left: 31%;
    height: 100%;
  }
  .row-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-image:  url('../../../public/img/new_title_2.jpg');
    background-image:  url('../../../public/img/new_title.png');
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    z-index: -100;

  }
}
</style>